import React from "react";
import logo from './logo.svg';
import './App.css';
import Login from './Login'
import {
  createBrowserRouter,
  RouterProvider,
  Route
} from "react-router-dom"

function App() {
  document.body.style = 'background-color: #f5f5f5;';
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Login />
    }
  ])

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;
